import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/3ad3dd40-9ca2-4cb8-8444-5842c6c4d011/components/global/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/3ad3dd40-9ca2-4cb8-8444-5842c6c4d011/components/global/DateSettings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/3ad3dd40-9ca2-4cb8-8444-5842c6c4d011/components/global/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/3ad3dd40-9ca2-4cb8-8444-5842c6c4d011/components/global/RealUserMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/3ad3dd40-9ca2-4cb8-8444-5842c6c4d011/components/global/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/build/3ad3dd40-9ca2-4cb8-8444-5842c6c4d011/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Marianne/static/Marianne-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Bold.woff2\",\"weight\":\"700\"}],\"fallback\":[\"arial\"]}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager" */ "/build/3ad3dd40-9ca2-4cb8-8444-5842c6c4d011/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/build/3ad3dd40-9ca2-4cb8-8444-5842c6c4d011/styles/typography.css");
